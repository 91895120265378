<template>
	<div class="print">
		<h2 class="qjc-fts-20 qjc-c-dark qjc-ftw-500">报告打印 </h2>
		
		<div class="search" @keyup.enter="query">
			<el-input
				v-model="searchTag"
				placeholder="请输入企业用户ID或手机号"
				class="qjc-fts-14"
			>
			</el-input>
			
			<el-button @click="query" :loading="submiting" class="qjc-fts-16">查询</el-button>
		</div>
		
		<el-table
			:data="user"
			stripe
			class="list"
		>
			<el-table-column
				prop="examdate"
				label="体检日期"
				class-name="examdate"
			>
			</el-table-column>
			<el-table-column
				prop="sex"
				label="客户性别"
				class-name="sex"
			>
			</el-table-column>
			<el-table-column
				prop="birth"
				label="出生日期"
				class-name="birth"
			>
			</el-table-column>
			<el-table-column
				label="套餐内容"
				class-name="package"
			>
				<template slot-scope="scope">
					<div class="package-cell" :title="scope.row.package == '2' ? (scope.row.custom.join('+').replace('base','基础体检套餐').replace('packageA','增值包-同族群PLM对比').replace('packageB','增值包-共病风险').replace('packageC','增值包-疾病风险远期预测').replace('packageD','增值包-健康促进方案').replace('packageFB','增值包-骨折风险评估')) : (scope.row.package == '1' ? '健康100' : scope.row.package == '3' ? '馨康母婴套餐' : '')">
						{{scope.row.package == '2' ? (scope.row.custom.join('+').replace('base','基础体检套餐').replace('packageA','增值包-同族群PLM对比').replace('packageB','增值包-共病风险').replace('packageC','增值包-疾病风险远期预测').replace('packageD','增值包-健康促进方案').replace('packageFB','增值包-骨折风险评估')) : (scope.row.package == '1' ? '健康100' : scope.row.package == '3' ? '馨康母婴套餐' : '')}}
					</div>
				</template>
			</el-table-column>
			<el-table-column
				class-name="oper"
				align="center"
			>
				<template slot-scope="scope">
					<el-button @click="lookReport(scope.row)" type="text">查看报告</el-button>
					<el-button @click="download(scope.row.id)" type="text">下载报告</el-button>
					<!-- <el-button @click="print(scope.row.id)">一键打印</el-button> -->
				</template>
			</el-table-column>
			
		</el-table>
		
		<!-- 分页 -->
		<el-pagination
			class="pagination qjc-texta-c"
			layout="prev, pager, next"
			hide-on-single-page
			:current-page.sync="pager.page"
			:page-count="pager.pagecount"
			:page-size="pager.pagesize"
			:total="pager.count"
			@current-change="pageChange">
		</el-pagination>
		
		<iframe id="iframe" class="qjc-wid-100 qjc-hei-100" :src="iframeUrl" frameborder="0"></iframe>
		
		<!-- 未安装打印插件 -->
		<div class="no-print qjc-fixed qjc-hide">
			<div class="qjc-absolute qjc-bg-fff qjc-texta-r">
				<p class="qjc-texta-l">未安装打印插件，请先安装</p>
				<span class="qjc-c-primary">关闭</span>
			</div>
		</div>
			
		
	</div>
</template>

<script>
	import { printBatchQuery } from '@/utils/axios'
	
	export default{
		name: 'reportPrint',
		data() {
			return {
				searchTag: '',//查询id或手机号
				
				user: [],// 客户信息
				
				submiting: false,//正在请求
				
				pager: {
					count: 0,
					page: 1,// 默认第一页
					pagecount: 0,
					pagesize: 12//默认一页12条数据
				},
				
				nowMobile: '',// 存储当前已查询企业用户id或手机号
				
				iframeUrl: ''// 下载报告路径
			}
		},
		mounted() {
			this.getList();
		},
		methods: {
			query() {
				if(this.searchTag){
					this.nowMobile = this.searchTag;
					this.getList();
				}else{
					this.$myAlert({
						title: '查询ID或手机号不能为空'
					});
				}
			},
			
			pageChange(page) {
				this.pager.page = page;
				this.getList();
			},
			
			getList() {
				printBatchQuery({
					mobile: this.nowMobile,
					page: this.pager.page,
					pagesize: this.pager.pagesize
				}).then(res => {
					// console.log(res);
					if(res.code == 200){
						if(res.data.data.length == 0){
							this.user = [];
							this.pager = {
								count: 0,
								page: 1,// 默认第一页
								pagecount: 0,
								pagesize: 12//默认一页12条数据
							},
							this.$myAlert({
								title: res.msg
							});
						}else{
							this.user = res.data.data;
							
							//分页
							var pager = res.data.pager;
							for(var key in pager){
								pager[key] = parseInt(pager[key]);
							}
							this.pager = pager;
						}
					}else{
						this.$myAlert({
							type: 'fail',
							content: res.msg
						});
					}
				});
			},
			
			lookReport(row) {
				if(row.package == '3'){ // 查看报告  跳转母婴
					window.open('/muying/report/classify.html?id='+row.id);
				} else {
					let openUrl = this.$router.resolve({
						path: "/reportPrint/lookReport",
						query: {
							id: row.id
						}
					});
					window.open(openUrl.href);
				}
				
			},
			// 下载报告
			download(id) {
				var iframeDoc = document.getElementById('iframe');
				
				var url = this.$router.resolve({
					path: "/reportPrint/printTemplate",
					query: {
						id: id,
						type: 'download'
					}
				}).href;
				
				// 弹出正在加载提示框
				this.$myAlert({
					title: '请稍候...'
				});
				
				if(this.iframeUrl == url && iframeDoc.contentWindow.getData){
					iframeDoc.contentWindow.getData();
				}else{
					this.iframeUrl = url;
				}
			},
			//打印报告
			print(id) {
				var iframeDoc = document.getElementById('iframe');
				
				var url = this.$router.resolve({
					path: "/reportPrint/printTemplate",
					query: {
						id: id,
						type: 'print'
					}
				}).href;
				
				if(this.iframeUrl == url && iframeDoc.contentWindow.getData){
					iframeDoc.contentWindow.getData();
				}else{
					this.iframeUrl = url;
				}
				
			}
		}
	}
</script>

<style scoped>
	.search >>> .el-input-group__prepend{
		width: 1.12rem;
	}
	.search >>> .el-input__inner{
		height: 0.36rem;
		line-height: 0.14rem;
		font-size: 0.14rem;
		padding: 0.1rem 0.12rem;
		background-color: #FAFBFC;
	}
	.search >>> .el-select .el-input__inner{
		text-align: center;
		padding-right: 0.25rem;
	}
	.search >>> .el-select .el-input__suffix{
		right: 0;
	}
	.search >>> .el-select .el-icon-arrow-up{
		line-height: 1;
	}
	.list >>> .el-table__body,
	.list >>> .el-table__footer, 
	.list >>> .el-table__header{
		table-layout: auto;
	}
	.list >>> table{
		width: 10.92rem;
	}
	.list.el-table--striped >>> .el-table__body tr.el-table__row--striped td,
	.list >>> thead th{
		background: #FAFBFC;
	}
	.list >>> .cell{
		padding-left: 0.24rem;
		padding-right: 0;
	}
	.list >>> thead th{
		/* display: inline-block; */
		color: #40444D;
		font-size: 0.14rem;
		font-weight: 500;
		text-align: left;
		border-bottom: none;
	}
	.list >>> td{
		color: #777F8F;
		font-size: 0.14rem;
		/* display: inline-block; */
		border-bottom: none;
	}
	.list >>> .package .cell{
		width: 3.03rem;
		white-space:nowrap;
		overflow:hidden;
		text-overflow:ellipsis;
	}
	.list >>> .examdate .cell{
		width: 1.95rem;
	}
	.list >>> .sex .cell{
		width: 1.38rem;
	}
	.list >>> .birth .cell{
		width: 1.87rem;
	}
	.list >>> .oper .cell{
		width: 3rem;
		padding-left: 0.12rem;
	}
	.pagination >>> button{
		width: 0.32rem;
		height: 0.32rem;
		line-height: 0.32rem;
	}
	.pagination >>> button .el-icon{
		font-size: 0.14rem;
	}
	.pagination >>> li{
		width: 0.32rem;
		height: 0.32rem;
		line-height: 0.32rem;
		font-size: 0.14rem;
		font-weight: 400;
		color: #40444D;
		border: 0.01rem solid transparent;
		border-radius: 0.08rem;
		margin: 0 0.06rem;
		padding: 0;
		overflow: visible;
	}
	.pagination >>> li:hover,
	.pagination >>> li.active{
		color: #6681FA;
		font-weight: 600;
		border-color: #6681FA;
		border-left: 0.01rem solid #6681FA;
	}
</style>
<style lang="scss" scoped>
	.print{
		.el-button.qjc-fts-16{
			font-size: 0.15rem;
		}
		
		.search{
			margin-top: 0.32rem;
			
			.el-input{
				width: 5.05rem;
			}
			.el-button{
				width: 0.72rem;
				height: 0.36rem;
				line-height: 0.36rem;
				padding: 0;
				margin-left: 0.16rem;
			}
		}
		.list{
			margin-top: 0.4rem;
			
			&::before{
				display: none;
			}
			.el-button{
				font-size: 0.14rem;
				padding: 0.06rem 0.08rem;
				
				+.el-button{
					margin-left: 0.24rem;
				}
			}
			
			.package-cell {
				white-space:nowrap;
				overflow:hidden;
				text-overflow:ellipsis;
			}
		}
		.pagination{
			margin-top: 0.24rem;
			padding-right: 0.24rem;
			text-align: center;
		}
	}
	.el-select-dropdown__item.selected{
		color: #6681FA;
	}
	#iframe{
		height: 0;
	}
	
	.no-print{
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,.5);
		font-size: 0.18rem;
		
		div{
			width: 5rem;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			border-radius: 0.08rem;
			
			p{
				padding: 0.24rem;
				border-bottom: 0.01rem solid #ccc;
			}
			span{
				display: inline-block;
				padding: 0.12rem 0.24rem;
				cursor: pointer;
			}
		}
	}
</style>
